import { useState, useEffect, useContext } from 'react';
import { useLocalStorage } from 'primereact/hooks';
import global from '../../context/global.context';
import { useTranslation } from 'react-i18next';

export const useFetch = (path, method, opt) => {
  const baseURL = opt.baseApi
    ? process.env[opt.baseApi]
    : process.env.REACT_APP_BASE_URL;
  const { onComplete, onError, onloading } = opt;
  const { t } = useTranslation();
  const { setBlockedPanel, toast } = useContext(global);

  const [token] = useLocalStorage(0, 'token');

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorFetch, setErrorFetch] = useState(null);
  const [pathreRetch, setPathreRetch] = useState(null);
  const [hasFetched, setHasFetched] = useState(false); // Nuevo estado para indicar si ya se ha realizado la petición

  useEffect(() => {
    setErrorFetch(null);
    if (path && !hasFetched) {
      // Verificar que aún no se ha hecho la petición
      setPathreRetch(path);
      const exec = async () => await getDataFetch();
      exec();
    }
    // eslint-disable-next-line
  }, [path, hasFetched]);

  useEffect(() => {
    if (data && onComplete) {
      onComplete(data);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (onloading) {
      onloading(loading);
    }
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (errorFetch && onError && !hasFetched) {
      onError(errorFetch);
    }
  }, [errorFetch, onError, hasFetched]);

  const getDataFetch = async () => {
    try {
      setBlockedPanel(true);
      const rs = await invoke();
      if (rs.ok) {
        const rsJson = await rs.json();
        setData(rsJson);
        setHasFetched(true); // Actualizar el estado indicando que se ha realizado la petición
      } else {
        const rsJson = await rs.json();
        setErrorFetch(rsJson);
        opt.onError && showError(rsJson, opt.severity, toast, t);
      }
    } catch (e) {
      const exObj = errorManager(e);
      setErrorFetch(exObj);
      showError(exObj, 'error', toast, t);
    } finally {
      setLoading(false);
      setBlockedPanel(false);
    }
  };

  const invoke = async (newPath) => {
    setLoading(true);
    const pathFetch = newPath ? newPath : path;
    const rs = await fetch(`${baseURL}${pathFetch}`, {
      method,
      body: opt.rq && JSON.stringify(opt.rq),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });
    return rs;
  };

  const refetch = async () => {
    let data;
    let error;
    try {
      console.log('refetch', pathreRetch);
      const rs = await invoke(pathreRetch);
      if (rs.ok) {
        const rsJson = await rs.json();
        setData(rsJson);
        data = rsJson;
      } else {
        const rsJson = await rs.json();
        setErrorFetch(rsJson);
        error = rsJson;
      }
    } catch (e) {
      const exObj = errorManager(e);
      error = exObj;
      setErrorFetch(exObj);
    } finally {
      setLoading(false);
    }
    return { data, error };
  };

  return { data, loading, errorFetch, refetch, reloadByFlag: setHasFetched };
};

export const useLazyFetch = (opt) => {
  const baseURL = opt && opt.baseApi
    ? process.env[opt.baseApi]
    : process.env.REACT_APP_BASE_URL;

  const { t } = useTranslation();
  const { setBlockedPanel, toast } = useContext(global);
  const [token] = useLocalStorage(0, 'token');

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorFetch, setErrorFetch] = useState(null);

  const getDataFetch = async (path, method, opt) => {
    let data = null;
    let error = null;
    let loading = null;
    try {
      setBlockedPanel(true);
      const rs = await invoke(path, method, opt);

      if (rs.ok) {
        const rsJson = await rs.json();
        setData(rsJson);
        data = rsJson;
      } else {
        const rsJson = await rs.json();
        setErrorFetch(rsJson);
        error = rsJson;
        opt.autoShowError && showError(rsJson, opt.severity, toast, t);
      }
    } catch (e) {
      const exObj = errorManager(e);
      error = exObj;
      setErrorFetch(exObj);
      showError(exObj, 'error', toast, t);
    } finally {
      setLoading(false);
      loading = false;
      setBlockedPanel(loading);
    }

    return {
      data: data,
      errorFetch: error,
      loading: loading,
    };
  };

  const invoke = async (path, method, opt) => {
    setLoading(true);
    const rs = await fetch(`${baseURL}${path}`, {
      method,
      body: opt && opt.rq && JSON.stringify(opt.rq),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        ...(opt &&
          opt.tokenTmp &&
          !token && { Authorization: `Bearer ${opt.tokenTmp}` }),
      },
    });
    return rs;
  };

  return { data, loading, errorFetch, getDataFetch };
};

export const useLazyFileFetch = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem('token');
  console.log('token', token);
  const [loading, setLoading] = useState(false);
  const [errorFetch, setErrorFetch] = useState(null);

  const getDataFetch = async (path, method, opt) => {
    let error = null;
    let loading = null;

    try {
      const response = await invoke(path, method, opt);
      if (response.ok) {
        const { fileName } = opt;
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName ? fileName : 'file.pdf';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      } else {
        const rsJson = await response.json();
        if (rsJson.error._errorCode) {
          const errorTmp = {
            _errorCode: rsJson.error._errorCode,
          };
          setErrorFetch(errorTmp);
        }
      }
    } catch (e) {
      const labelErrror = 'api.fail-fetch';
      const unexpectedError = 'api.unexpected-error';
      const isFailToFetch =
        e.message.toUpperCase() === 'failed to fetch'.toUpperCase();
      const errorT = isFailToFetch
        ? { error: { _errorCode: labelErrror } }
        : { ...error, _errorCode: unexpectedError };
      setErrorFetch(errorT);
    } finally {
      setLoading(false);
      loading = false;
    }

    return {
      errorFetch,
      loading: loading,
    };
  };

  const invoke = async (path, method, opt) => {
    setLoading(true);
    const rs = await fetch(`${baseURL}${path}`, {
      method,
      body: opt && opt.rq && JSON.stringify(opt.rq),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
        ...(opt &&
          opt.tokenTmp &&
          !token && { Authorization: `Bearer ${opt.tokenTmp}` }),
      },
    });
    return rs;
  };

  return { loading, errorFetch, getDataFetch };
};

const showError = (e, severity = 'error', toast, t) => {
  if (e && e.error) {
    const errorIsKey = e.error.split('.').length > 1;
    const errorArgs = e.errorArgs ? e.errorArgs : '';
    if (errorIsKey) {
      const detail = e.errorArgs ? ` errorArgs: ${errorArgs}` : ``;
      toast.current.show({
        severity: severity,
        summary: `api.${severity}`,
        detail: t(`api.${e.error}`) + detail,
        life: 3000,
      });
    } else {
      const detail = e.errorArgs
        ? `${e.error} errorArgs: ${errorArgs}`
        : e.error;
      toast.current.show({
        severity: severity,
        summary: `api.${severity}`,
        detail: detail,
        life: 3000,
      });
    }
  }
};

const errorManager = (error) => {
  const { message } = error;
  switch (message) {
    case 'Failed to fetch':
      return {
        timestamp: new Date().toISOString(),
        status: 503,
        error: 'failed.fetch',
        errorArgs: null,
        errors: null,
      };
    default:
      return {
        timestamp: new Date().toISOString(),
        status: 503,
        error: 'unexpected.error',
        errorArgs: null,
        errors: null,
      };
  }
};
