import React, { useRef, useState } from "react";
import { InputText } from 'primereact/inputtext';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Button } from 'primereact/button';
import '../css/SectionSix.css';
import imgfauderia from '../../../../assets/images/lficon.png';

import Image01 from './carrusel/Img01.png';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useLazyFetch } from "../../../../hooks/common/useFetchV2";
import { Toast } from "primereact/toast";

const SectionSix = ({ reference }) => {
    const [token, setToken] = useState("");
    const { getDataFetch } = useLazyFetch();
    const toast = useRef(null);
    const [validacion, setValidacion] = useState({
        captacha: true,
        email: true
    });
    const [email, setEmail] = useState('');
    const newletter = async () => {
        if (validar()) {
            let respuesta = await getDataFetch('/public/newsletter', 'POST', { rq: { email: email, is_active: 1 } });
            if (respuesta.errorFetch) {
                //console.log(respuesta.errorFetch)
                toast.current.show({
                    detail: 'No se logró registrar el correo, intentelo más tarde',
                    summary: 'Error',
                    severity: 'error'
                });

            } else {
                toast.current.show({
                    detail: 'Correo registrado, espere por nuevas promociones',
                    summary: 'Éxito',
                    severity: 'success'
                });

                captchaRef.current.resetCaptcha();
                setEmail('');
            }
        }
    }

    const validar = () => {
        let auxVal = validacion;
        if (email !== null && email !== '')
            auxVal.email = true;
        else
            auxVal.email = false
        if (token !== null && token !== '')
            auxVal.captacha = true
        else
            auxVal.captacha = false
        setValidacion({ ...auxVal });
        return auxVal.email && auxVal.captacha
    }

    const captchaRef = useRef(null);
    return (
        <>
            <Toast ref={toast}></Toast>
            <div className="grid mx-2 lg:mx-8" ref={reference}>
                <div className="col-12  md:col-4 lg:col-4 lg:mt-0 flex flex-column justify-content-center">
                    <div className="flex justify-content-center">
                        <img src={imgfauderia} alt={Image01} className="w-5" />
                    </div>
                    <p className="footer-title">La Fudería.</p>
                    <div className="flex justify-content-evenly">
                        <div className="inline font-bold text-center p-5 border-round"><a style={{color:'#4b5563'}} href="https://www.facebook.com/profile.php?id=61555553096533"><i className="pi pi-facebook" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                        <div className="inline font-bold text-center p-5 border-round"><a style={{color:'#4b5563'}} href="https://www.instagram.com/lafuderia/"><i className="pi pi-instagram" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                        <div className="inline font-bold text-center p-5 border-round"><a style={{color:'#4b5563'}} href="https://x.com/LaFuderia"><i className="pi pi-twitter" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                        <div className="inline font-bold text-center p-5 border-round"><a style={{color:'#4b5563'}} href="http://www.youtube.com/@LaFuder%C3%ADa"><i className="pi pi-youtube" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                    </div>

                </div>

                <div className="col-12  text-center md:col-4 lg:col-4 lg:mt-0">
                    <p className=" font-bold sitemap-title">SITEMAP</p>
                    <p className=" font-bold sitemap">NOSOTROS</p>
                    <p className=" font-bold sitemap">PRODUCTOS</p>
                    <p className=" font-bold sitemap">MI CUENTA</p>
                    <p className=" font-bold sitemap">LEGALES</p>
                    <p className=" font-bold sitemap">CONTACTO</p>
                </div>

                <div className="col-12  md:col-4 lg:col-4 lg:mt-0 px-3">
                    <p className="font-bold sitemap-title text-center lg:text-left">NEWSLETTER</p>
                    <p className="sitemap text-center lg:text-left">Suscríbete para recibir noticias, acceder a ofertas exclusivas y más.</p>
                    <div className="field">
                        <IconField className="col-12" iconPosition="left">
                            <InputIcon style={{ top: "1.5rem", left: '1rem' }} className="pi pi-spin pi-at"> </InputIcon>
                            <InputText value={email} className="col-12 p-inputtext-lg text-center" type="email" placeholder="Ingresa tu Correo Electrónico..." onChange={(e) => { setEmail(e.target.value) }} />
                        </IconField>
                    </div>
                    <div className="flex w-full justify-content-center">
                        <HCaptcha
                            ref={captchaRef}
                            sitekey="cc10d24c-4514-4416-aa06-7cc20f466d04"
                            onVerify={(token) => setToken(token)}
                            onExpire={e => setToken("")}
                        />
                    </div>
                    <div className="field">
                        <Button className="col-12" size="large" label="SUSCRIBIRSE" onClick={newletter} />
                    </div>
                    {!validacion.email && <p className="small-error">Ingrese un correo</p>}
                    <br />
                    {!validacion.captacha && <p className="small-error">Resuelva el captcha</p>}
                </div>
            </div>


            <div className="grid mt-2 h-13rem w-full" style={{ overflow: 'hidden' }}>
                <img src={'https://www.shutterstock.com/image-vector/long-banner-set-doodle-seafood-260nw-2188553415.jpg'} alt={'bannerBottom'} className="w-full h-15rem" style={{ objectFit: 'cover' }} />
            </div>
        </>
    );
}

export default SectionSix;