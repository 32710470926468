import { useState, useEffect } from "react";
import { useLazyFetch } from "../../../../hooks/common/useFetchV2";

const useFormPayHook = () => {
    const { getDataFetch: codigoPostaal } = useLazyFetch();
    const { getDataFetch: busquedaCliente } = useLazyFetch();
    const { getDataFetch: validateEmail } = useLazyFetch();
    const { getDataFetch: envioDatosPago } = useLazyFetch();
    const [direccion, setDireccion] = useState(null); 
    const [dataRegsitro, setDataRegsitro] = useState(null);
    const [deviceSessionId, setDeviceSessionId] = useState('');
    const [openpay, setOpenPay] = useState(false);

    const getCodigoPostal = async (cp) => {
        const { data, errorFetch } = await codigoPostaal(`/public/catalogs/find-by-cp/${cp}`, 'GET', {});
        console.log(data);
        if (data) {
            setDireccion(data);
            return data;
        }
        console.error(errorFetch);
    };

    const getBuscaCorreo = async (correo) => {
        const { data, errorFetch } = await busquedaCliente(`/public/checkout/profile/${correo}`, 'GET', {});

        if (data) {
            setDataRegsitro(data);
            return data;
        }
        console.error(errorFetch);

    };

    const postInsertCart = async (rq) => {
        const { data, errorFetch } = await validateEmail(`/public/insert-cart`, 'POST', { rq });

        if (data) {
            console.log(data);
            return data;
        }
        console.error(errorFetch);
        return errorFetch;

    };


    const getSearchCard = async (idCustomer) => {
        const { data, errorFetch } = await busquedaCliente(`/OpenPay/card-pay/${idCustomer}`, 'GET', {});

        if (data) {
            console.log(data);
            return data;
        }
        console.error(errorFetch);
        return errorFetch;
    };


    const postValidateEmail = async (email, code) => {
        console.log(email, code);
        const rq = {
            "email": email,
            "code": code
        }

        const { data, errorFetch } = await validateEmail(`/public/checkout/profile/validateEmail`, 'POST', { rq });

        if (data) {
            console.log(data);
            return data;
        }
        console.error(errorFetch);

    };

   
    const onSubmit = async (rq) => {
        rq = {
            ...rq,
            "deviceSessionId": deviceSessionId
        }

        try {
            const { data, errorFetch } = await envioDatosPago(`/OpenPay/data-pay`, 'POST', { rq });
            // const { data, errorFetch } = await busquedaCliente(`/public/checkout/profile/jeje1@mail.com`, 'GET', {});

            if (data) {
                console.log("Datos del fetch:", data);
                return data; // Retorna los datos si todo está bien
            } else {
                throw new Error(errorFetch || 'Error desconocido');
            }
        } catch (error) {
            console.error("Error en el envío de datos:", error);
            return { error: error.message }; // Retorna el mensaje de error si ocurre una excepción
        }
    };

    useEffect(() => {
        // Función para cargar el script de OpenPay dinámicamente
        const loadOpenPayScript = () => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = 'https://js.openpay.mx/openpay-data.v1.min.js';
                script.async = true;
                script.onload = resolve; // Resolvemos la promesa cuando el script se haya cargado
                script.onerror = reject; // Rechazamos la promesa si hay un error
                document.body.appendChild(script);
            });
        };

        const setupDeviceSessionId = () => {
            if (window.OpenPay) {
                const sessionId = window.OpenPay.deviceData.setup();
                setDeviceSessionId(sessionId);
            } else {
                console.error('OpenPay no está disponible');
            }
        };

        // Cargar el script y luego configurar el deviceSessionId
        loadOpenPayScript().then(() => {
            setupDeviceSessionId();
        }).catch((error) => {
            console.error('Error al cargar el script de OpenPay:', error);
        });
    }, []);

    return {
        functions: {
            onSubmit,
            getCodigoPostal,
            getBuscaCorreo,
            postValidateEmail,
            postInsertCart,
            getSearchCard
        },
        states: {
            direccion,
            dataRegsitro,
            openpay
        }
    };
}

export default useFormPayHook;