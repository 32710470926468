import AboutUs from "./componets/SectionTwo";
import SectionFive from "./componets/SectionFive";
import SectionOne from "./componets/SectionOne";
import SectionThree from "./componets/SectionThree";
import SectionSix from "./componets/SectionSix";
import './css/index.css';
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";



const LandingPage = () => {

    const sectionOneRef = useRef(null);
    const sectionTwoRef = useRef(null);
    const sectionThreeRef = useRef(null);
    const sectionFiveRef = useRef(null);
    const sectionSixRef = useRef(null);

    const pageState = useSelector((state) => state.pageStateReducer);

    useEffect(() => {
        //console.log(pageState.sectionPage);
        scrollView();
    }, [pageState.sectionPage])

    const scrollView = () => {
        switch (pageState.sectionPage) {
            case 'ONE': {
                sectionOneRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
                break;
            }
            case 'TWO': {
                sectionTwoRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                console.log("TWO", sectionOneRef);
                break;
            }
            case 'THREE': {
                sectionThreeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                console.log("THREE", sectionThreeRef);
                break;
            }
            case 'FIVE': {
                sectionFiveRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                console.log("FOUR", sectionFiveRef);
                break;
            }
            case 'SIX': {
                sectionSixRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                console.log("FIVE", sectionSixRef);
                break;
            }
        }
    }

    return (
        <>
            <SectionOne reference={sectionOneRef} />
            <SectionThree reference={sectionThreeRef} />
            <AboutUs reference={sectionTwoRef} />
            <SectionFive reference={sectionFiveRef} />
            <SectionSix reference={sectionSixRef} />
        </>
    );
}

export default LandingPage;