import { Chip } from "primereact/chip";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import { useState } from "react";
import { setCategorySelect, setFilters, setFiltersSelect, setPriceItem, setPriceKg, setSampleKg, setSubcategorySelect } from "../../../../redux/actions/pageState/pageStateActions";
import { useDispatch, useSelector } from "react-redux";

const FilterProducts = ({ records }) => {
    const statePage = useSelector((state) => state.pageStateReducer);
    const dispatch = useDispatch();
    const { filters, filtersSelect, rangePrice, rangeGR, rangePriceKG, category, categorySelect, subcategory, subcategorySelect } = statePage;
    const filterProduct = (categorys) => {
        //console.log(categorys);
        return (

            categorys?.map((value, index) => {

                return (
                    <div className="list-filter pt-3">
                        <p className="text-2xl font-bold title-filter" key={'cat' + index}>{value.name}</p>
                        <p>
                            {value.subcategory?.map((subValue, subIndex) => {
                                return (
                                    <p onClick={() => { addFilter(subValue) }} style={{ cursor: 'pointer', userSelect: 'none' }} className="text-lg font-bold subcategory-filter" key={subIndex + 'cat' + index}>{subValue.featureValue}</p>
                                )
                            })}
                        </p>
                    </div>
                )
            })
        )
    }
    const categoryFilter = (categorys) => {
        //console.log(categorys);
        return (

            categorys?.map((value, index) => {

                return (
                    <div className="list-filter pt-3">
                        <p className="text-2xl font-bold title-filter" key={'cat' + index}>{value.name}</p>
                        <p>
                            {value.subcategory?.map((subValue, subIndex) => {
                                return (
                                    <p onClick={() => { addCategory(subValue) }} style={{ cursor: 'pointer', userSelect: 'none' }} className="text-lg font-bold subcategory-filter" key={subIndex + 'cat' + index}>{subValue.featureValue}</p>
                                )
                            })}
                        </p>
                    </div>
                )
            })
        )
    }
    const subcategoryFilter = (categorys) => {
        //console.log(categorys);
        return (

            categorys?.map((value, index) => {

                return (
                    <div className="list-filter pt-3">
                        <p className="text-2xl font-bold title-filter" key={'cat' + index}>{value.name}</p>
                        <p>
                            {value.subcategory?.map((subValue, subIndex) => {
                                return (
                                    <p onClick={() => { addSubCategory(subValue) }} style={{ cursor: 'pointer', userSelect: 'none' }} className="text-lg font-bold subcategory-filter" key={subIndex + 'cat' + index}>{subValue.featureValue}</p>
                                )
                            })}
                        </p>
                    </div>
                )
            })
        )
    }

    const addCategory = (filer) => {
        let exist = categorySelect.find(objeto => objeto.featureValue === filer.featureValue);
        if (!exist) {
            let auxFilter = [...categorySelect];
            auxFilter.push(filer);
            dispatch(setCategorySelect(auxFilter));
        }

    }
    const removeCategory = (filer) => {
        let exist = categorySelect.find(objeto => objeto.featureValue === filer.featureValue);
        if (exist) {
            let auxFilter = categorySelect.filter(objeto => objeto.featureValue !== filer.featureValue);
            dispatch(setCategorySelect([...auxFilter]));
        }

    }
    const addSubCategory = (filer) => {
        let exist = subcategorySelect.find(objeto => objeto.featureValue === filer.featureValue);
        if (!exist) {
            let auxFilter = [...subcategorySelect];
            auxFilter.push(filer);
            console.log('push',auxFilter);
            dispatch(setSubcategorySelect(auxFilter));
        }

    }
    const removeSubCategory = (filer) => {
        let exist = subcategorySelect.find(objeto => objeto.featureValue === filer.featureValue);
        if (exist) {
            let auxFilter = subcategorySelect.filter(objeto => objeto.featureValue !== filer.featureValue);
            dispatch(setSubcategorySelect([...auxFilter]));
        }

    }

    const addFilter = (filer) => {
        let exist = filtersSelect.find(objeto => objeto.featureValue === filer.featureValue);
        if (!exist) {
            let auxFilter = [...filtersSelect];
            auxFilter.push(filer);
            dispatch(setFiltersSelect(auxFilter));
        }

    }
    const removeFilter = (filer) => {
        let exist = filtersSelect.find(objeto => objeto.featureValue === filer.featureValue);
        if (exist) {
            let auxFilter = filtersSelect.filter(objeto => objeto.featureValue !== filer.featureValue);
            dispatch(setFiltersSelect([...auxFilter]));
        }

    }

    return (
        <div className="card-container p-3">
            <p className="title-menu-products">Nuestros Productos</p>
            <p className="font-bold">total de resultados: {records}</p>
            <div style={{ minHeight: '3rem' }}>
                <div className="card-container">
                    {
                        filtersSelect?.map((item, index) => {
                            return (
                                <Chip className="chip-desc" style={{ cursor: 'pointer', userSelect: 'none' }} label={item.featureValue} onClick={() => { removeFilter(item) }} icon="pi pi-trash" />
                            )
                        })
                    }
                    {
                        categorySelect?.map((item, index) => {
                            return (
                                <Chip className="chip-cat" style={{ cursor: 'pointer', userSelect: 'none' }} label={item.featureValue} onClick={() => { removeCategory(item) }} icon="pi pi-trash" />
                            )
                        })
                    }
                    {
                        subcategorySelect?.map((item, index) => {
                            return (
                                <Chip className="chip-sub" style={{ cursor: 'pointer', userSelect: 'none' }} label={item.featureValue} onClick={() => { removeSubCategory(item) }} icon="pi pi-trash" />
                            )
                        })
                    }
                </div>
            </div>
            <p className="font-bold text-2xl">Precio por unidad</p>
            <div className="flex mb-4 w-full gap-3">
                <div className="w-6">
                    <label htmlFor="minmaxfraction" className="font-bold block mb-2">Mínimo</label>
                    <InputText type="numbre" style={{ width: '100%' }} inputId="minmaxfraction" value={rangePrice[0]} onChange={(e) => { dispatch(setPriceItem([e.target.value, rangePrice[1]])) }} />
                </div>
                <div className="w-6">
                    <label htmlFor="minmaxfraction" className="font-bold block mb-2">Máximo</label>
                    <InputText type="number" style={{ width: '100%' }} inputId="minmaxfraction" value={rangePrice[1]} onChange={(e) => { dispatch(setPriceItem([rangePrice[0], e.target.value])) }} />
                </div>
            </div>
            <Slider min={0} max={1000} value={rangePrice} onChange={(e) => { dispatch(setPriceItem(e.value)) }} range />
            <div>
                {
                    categoryFilter(category)
                }
            </div>
            <div>
                {
                    subcategoryFilter(subcategory)
                }
            </div>
            {/* <p className="font-bold text-2xl mt-3">Precio por Kilogramo</p>
            <div className="flex mb-4 w-full gap-3">
                <div className="w-6">
                    <label htmlFor="minmaxfraction" className="font-bold block mb-2">Mínimo</label>
                    <InputText type="numbre" style={{ width: '100%' }} inputId="minmaxfraction" value={rangePriceKG[0]} onChange={(e) => { dispatch(setPriceKg([e.target.value, rangePriceKG[1]])) }} />
                </div>
                <div className="w-6">
                    <label htmlFor="minmaxfraction" className="font-bold block mb-2">Máximo</label>
                    <InputText type="number" style={{ width: '100%' }} inputId="minmaxfraction" value={rangePriceKG[1]} onChange={(e) => { dispatch(setPriceKg([rangePriceKG[0], e.target.value])) }} />
                </div>
            </div>
            <Slider min={0} max={1000} value={rangePriceKG} onChange={(e) => { dispatch(setPriceKg(e.value)) }} range />

            <p className="font-bold text-2xl mt-3">Gramos de presentación</p>
            <div className="flex mb-4 w-full gap-3">
                <div className="w-6">
                    <label htmlFor="minmaxfraction" className="font-bold block mb-2">Mínimo</label>
                    <InputText type="numbre" style={{ width: '100%' }} inputId="minmaxfraction" value={rangeGR[0]} onChange={(e) => { dispatch(setSampleKg([e.target.value, rangeGR[1]])) }} />
                </div>
                <div className="w-6">
                    <label htmlFor="minmaxfraction" className="font-bold block mb-2">Máximo</label>
                    <InputText type="number" style={{ width: '100%' }} inputId="minmaxfraction" value={rangeGR[1]} onChange={(e) => { dispatch(setSampleKg([rangeGR[0], e.target.value])) }} />
                </div>
            </div>
            <Slider min={0} max={1000} value={rangeGR} onChange={(e) => { dispatch(setSampleKg(e.value)) }} range /> */}
            <div>
                {
                    filterProduct(filters)
                }
            </div>
        </div>
    )
}
export default FilterProducts