import { TYPE_PRODUCT_VIEWER } from '../../types/productViewer/productViewerTypes';

const initialState = {
  layout: 'grid',
  products: [],
};

const productViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE_PRODUCT_VIEWER.VIEWER_SET_LAYOUT: {
      return {
        ...state,
        layout: action.payload,
      };
    }
    case TYPE_PRODUCT_VIEWER.VIEWER_SET_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
      };
    }
    default:
      return state;
  }
};

export default productViewerReducer;
