import { Carousel } from 'primereact/carousel';
import useProductsViewHook from '../hooks/useProductsViewHook';
import { DataView } from 'primereact/dataview';
import '../../../home/LandingPage/css/products.css';
import { PrimeIcons } from 'primereact/api';

const customPrevIcon = <i className={PrimeIcons.CHEVRON_LEFT}></i>;
const customNextIcon = <i className={PrimeIcons.CHEVRON_RIGHT}></i>;

const ProductsView = (props) => {
  const { type } = props;
  const { states, functions } = useProductsViewHook();
  const { listTemplate,listTemplateCarousel } = functions;
  const { hederJsx, layout, products, responsiveOptions } = states;


  return (
    <div className="flex justify-content-center">
      {type === 'CARRUSEL' ?
        <Carousel
          value={products}
          numVisible={3}
          numScroll={3}
          responsiveOptions={responsiveOptions}
          itemTemplate={listTemplateCarousel}
          className="carousel-custom"
          prevIcon={customPrevIcon} // Usa tu propio icono para la flecha anterior
          nextIcon={customNextIcon} // Usa tu propio icono para la flecha siguiente
          showIndicators={false}
        />
        :
        <DataView
          value={products}
          listTemplate={listTemplate}
          layout={layout}
          header={hederJsx}
          paginator
          rows={6}
          className="data-view-custom"
        />
      }
    </div>
  );
};

export default ProductsView;
